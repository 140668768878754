var redesignedIconId = 3;

var initialized;

var promise = new Promise(function(resolve) {
    initialized = resolve;
});

function init(manager) {
    initialized(manager);
}

function registerFavicon(id) {
    return promise.then(function(manager) {
        manager.registerFavicon((id === 0)
            ? redesignedIconId
            : id);
    });
}

function removeFavicon(id) {
    return promise.then(function(manager) {
        manager.removeFavicon((id === 0)
            ? redesignedIconId
            : id);
    });
}

export default { init, registerFavicon, removeFavicon };

export { init, registerFavicon, removeFavicon };
